<template>
  <div class="standard-page">
    <!-- Show the button to go back -->
    <v-btn
      text
      class="mb-4"
      color="primary"
      @click="$router.go(-1)"
    >
      <v-icon left>
        arrow_back
      </v-icon>

      Back
    </v-btn>

    <!-- Show the group inputs -->
    <v-card flat class="pt-8 px-md-6">
      <v-card-text class="mb-4">
        <v-row>
          <v-col cols="12" md="6">
            <!-- Show the input here -->
            <v-text-field
              v-model="formData.name"
              :error-messages="$v.formData.name.$anyError ? ['Please enter a valid name'] : null"
              @blur="$v.formData.name.$touch"
              placeholder="Winter Sales"
              label="Report Name"
              outlined
            />
          </v-col>

          <!-- Show a date range selector -->
          <v-col cols="12" md="6">
            <date-range-selector
              :max="dayjs().format('YYYY-MM-DD')"
              :min="dayjs().subtract(isAdmin ? 365 : 90, 'day').format('YYYY-MM-DD')"
              :value="dateRangeValue"
              :default-date-range="1"
              :max-date-range="isAdmin ? 365 : 90"
              @input="handleDateRangeValue"
            />
          </v-col>

          <v-col
            cols="12"
            class="pb-6"
          >
            <!-- Show a switch button for sponsored filter -->
            <div class="d-flex align-center justify-end">
              <span class="mr-4 text-subtitle-2">
                Only Sponsored Posts?
              </span>

              <v-switch
                v-model="formData.onlySponsoredPosts"
                class="ma-0"
                color="primary"
                hide-details
                inset
              ></v-switch>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Show the each report inputs -->
    <v-card
      v-for="(report, index) in $v.formData.reports.$each.$iter"
      :key="report.$model.symbol"
      class="mt-12"
      flat
    >
      <v-card-text class="mb-4">
        <div class="px-3">
          <v-btn
            v-if="formData.reports.length > 2"
            fab
            top
            small
            right
            absolute
            depressed
            color="primary"
            @click="() => formData.reports.splice(index, 1)"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </div>

        <v-row class="pt-6 px-md-6">
          <v-col cols="12" md="6">
            <!-- Show the input here -->
            <v-text-field
              v-model="report.name.$model"
              :error-messages="report.name.$anyError ? ['Please enter a valid name'] : null"
              @blur="report.name.$touch"
              placeholder="Myntra Winter Sale"
              label="Brand Name"
              outlined
            />
          </v-col>

          <v-col cols="12" md="6">
            <!-- Show the input for hashtags -->
            <v-combobox
              v-model="report.hashtags.$model"
              label="Hashtags"
              :allow-overflow="true"
              placeholder="#EndOfTheReasonSale #BigBillionDays #AmazonPrimeDay"
              multiple
              outlined
              small-chips
              deletable-chips
              hint="Mention tags related to your campaign, press enter to add more"
              :error-messages="report.hashtags.$anyError ? [getHashtagErrorMessage(report)] : null"
              @focus="didEditData = true"
              @input="handleHashtagInput(report)"
            ></v-combobox>
          </v-col>

          <v-col cols="12" md="6">
            <!-- Show the input for hashtags -->
            <v-combobox
              v-model="report.usernames.$model"
              label="Usernames"
              :allow-overflow="true"
              placeholder="@flipkart @amazon @myntra"
              multiple
              outlined
              small-chips
              deletable-chips
              hint="Mention handles related to your campaign, press enter to add more"
              :error-messages="report.usernames.$anyError ? ['Please enter valid usernames'] : null"
              @focus="didEditData = true"
              @input="handleUsernameInput(report)"
            ></v-combobox>
          </v-col>

          <v-col cols="12" md="6">
            <!-- Show the input for hashtags -->
            <v-combobox
              v-model="report.keywords.$model"
              label="Keywords"
              :allow-overflow="true"
              placeholder="Winter Sales, Diwali Offers"
              multiple
              outlined
              small-chips
              deletable-chips
              hint="Mention keywords related to your campaign, press enter to add more"
              :error-messages="report.keywords.$anyError ? ['Please enter valid keywords'] : null"
              @input="report.keywords.$touch"
            ></v-combobox>
          </v-col>

          <v-col
            cols="12"
          >
            <v-row
              class="align-center"
            >
              <v-col
                cols="6"
                sm="4"
              >
                <!-- Show the platforms selector -->
                <div style="margin-top: -16px">
                  <div class="mb-2">
                    Social Networks
                  </div>
                  <div class="d-flex items-center">
                    <v-img
                      v-for="platform in Object.keys(report.$model.platforms)"
                      :key="platform"
                      :class="{ 'opaque': !report.$model.platforms[platform] }"
                      @click="() => (report.$model.platforms[platform] = !report.$model.platforms[platform])"
                      :src="`/img/socials/${platform}.svg`"
                      class="mr-3 pointer"
                      max-width="40"
                      height="40"
                    ></v-img>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- Show a few radio buttons for hashtag condition -->
        <div v-if="report.hashtags.$model.length > 1">
          <v-divider class="my-4" />

          <!-- Show the text -->
          <div class="text-subtitle-1">
            You've entered multiple hashtags, should the crawler fetch posts that contain all of them or any of them?
          </div>

          <v-radio-group
            v-model="report.hashtagCondition.$model"
          >
            <v-radio
              value="any"
              label="Any of them"
            ></v-radio>

            <v-radio
              value="all"
              label="All of them"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- Show a few radio buttons for username condition -->
        <div v-if="report.usernames.$model.length > 1">
          <v-divider class="my-4" />

          <!-- Show the text -->
          <div class="text-subtitle-1">
            You've entered multiple usernames, should the crawler fetch posts that have tagged all of them or any of them?
          </div>

          <v-radio-group
            v-model="report.usernameCondition.$model"
          >
            <v-radio
              value="any"
              label="Any of them"
            ></v-radio>

            <v-radio
              value="all"
              label="All of them"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- Show a few radio buttons for keyword condition -->
        <div v-if="report.keywords.$model.length > 1">
          <v-divider class="my-4" />

          <!-- Show the text -->
          <div class="text-subtitle-1">
            You've entered multiple keywords, should the crawler fetch posts that contain all of them or any of them?
          </div>

          <v-radio-group
            v-model="report.keywordCondition.$model"
          >
            <v-radio
              value="any"
              label="Any of them"
            ></v-radio>

            <v-radio
              value="all"
              label="All of them"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- If the user has entered hashtag as well as username, show radio options -->
        <div v-if="report.hashtags.$model.length > 0 && report.usernames.$model.length > 0">
          <v-divider class="my-4" />

          <!-- Show the text -->
          <div class="text-subtitle-1">
            You've entered both hashtags and usernames, should the crawler fetch posts that contain both of them or any of them?
          </div>

          <v-radio-group
            v-model="report.hashtagAndUsernameCondition.$model"
          >
            <v-radio
              value="any"
              label="Any of them"
            ></v-radio>

            <v-radio
              value="all"
              label="Both of them"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>
    </v-card>

    <!-- Show the actions -->
    <v-card flat class="pt-8 px-md-6 mt-8">
      <v-card-actions class="pl-4 pb-8 flex-wrap">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip
              label
              color="primary"
              v-on="on"
            >
              <v-icon
                left
                small
              >
                account_balance
              </v-icon>

              {{ nFormatter(availableModuleUsage) }} Reports
            </v-chip>
          </template>

          <span>
            You can generate {{ availableModuleUsage }} more reports
          </span>
        </v-tooltip>

        <v-spacer />

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              color="primary"
              @click="pushReportToForm"
              :disabled="formData.reports.length >= maxReports"
            >
              <v-icon left>
                add
              </v-icon>

              Add Report ({{ formData.reports.length }}/{{ maxReports }})
            </v-btn>
          </template>

          <span>
            Maximum 5 reports can be added
          </span>
        </v-tooltip>

        <v-btn
          depressed
          color="primary"
          @click="validate"
          :disabled="availableModuleUsage <= 0 || isLoading || isValidating"
          :loading="isLoading || isValidating"
        >
          {{ didEditData ? "Continue" : "Create Report" }}

          <v-icon right>
            arrow_forward
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// Import helper functions
import { required, minLength, maxLength } from "vuelidate/lib/validators"

// Import child components
const AgeSelector = () => import(/* webpackChunkName: "age-selector" */ "@/blocks/common/selectors/AgeSelector.vue")
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")
const DateRangeSelector = () => import(/* webpackChunkName: "date-range-selector" */ "@/blocks/common/form/DateRangeSelector.vue")

// Define function for new reports
const initialReportData = () => ({
  symbol: Symbol(),

  name: "",
  hashtags: [],
  usernames: [],
  keywords: [],
  platforms: {
    instagram: true,
    tiktok: false
  },

  // The condition for multiple hashtags
  hashtagCondition: "any",
  usernameCondition: "any",
  keywordCondition: "any",

  // The condition for usernames and hashtags
  hashtagAndUsernameCondition: "any",

  // Whether or not to only fetch sponsored posts
  onlySponsoredPosts: false
})

// Export the SFC
export default {
  // Name of the component
  name: "MentionTrackingGroupForm",

  // Register children components
  components: {
    AgeSelector,
    WeightSelector,
    DateRangeSelector
  },

  // Accept incoming data from parent
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not is validating the form
    isValidating: false,

    // If we edited the form data
    didEditData: true,

    // The date range value
    dateRangeValue: null,

    // The visible menu dialogs
    dialog: {
      shouldShowStartDate: false,
      shouldShowEndDate: false
    },

    // The form data to be used
    formData: {
      name: "",
      startDate: "",
      endDate: "",
      onlySponsoredPosts: false,

      reports: [initialReportData(), initialReportData()]
    },

    // Maximum number of reports allowed
    maxReports: 5
  }),

  // Define computable readonly variables
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("mention-tracking-group")
    },

    /**
     * Whether or not the logged in user is an admin
     *
     * @returns {Boolean}
     */
    isAdmin() {
      return this.$store.getters["auth/isAdmin"]
    },

    /**
     * Get the formatted value from startDate
     *
     * @returns {String|Null}
     */
    readableStartDate() {
      return this.formData.startDate ? dayjs(this.formData.startDate).format("ll") : null
    },

    /**
     * Get the formatted value from endDate
     *
     * @returns {String|Null}
     */
     readableEndDate() {
      return this.formData.endDate ? dayjs(this.formData.endDate).format("ll") : null
    }
  },

  // Define validations for this form
  validations() {
    // Define the object
    const object = {
      formData: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(200)
        },
        startDate: {
          required,
          minimum: (v) => dayjs().diff(dayjs(v), 'day') <= dayjs().diff(dayjs().subtract(this.isAdmin ? 12 : 3, "month").format("YYYY-MM-DD"), 'day')
        },
        endDate: {
          required,
          minimum: (v) => dayjs(v).diff(this.formData.startDate, 'day') >= 1
        },

        reports: {
          $each: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(200)
            },
            hashtags: {
              maxLength: maxLength(10),
              $each: {
                regex: (v) => regex.hashtag.test(v),
              }
            },
            usernames: {
              maxLength: maxLength(10),
              $each: {
                regex: (v) => regex.username.test(v),
              }
            },
            keywords: {
              maxLength: maxLength(10),
            },
          }
        }
      }
    }

    // Return the object
    return object
  },

  // Watch for any changes
  watch: {
    formData: {
      deep: true,
      handler() {
        // If we have edited the data
        if (this.didEditData === false) {
          // Update the flag
          this.didEditData = true
        }
      }
    }
  },

  // Define method functions
  methods: {
    /**
     * Copy the value from child to current state
     *
     * @param {null|Array} value
     */
    async handleDateRangeValue(value) {
      // Copy the value to local value
      this.dateRangeValue = value

      // Check if it's an array
      if (value !== null) {
        // Also update the filter values
        this.formData.startDate = value[0] || null
        this.formData.endDate = value[1] || null

        // Validate the inputs
        await this.$v.formData.startDate.$touch()
        await this.$v.formData.endDate.$touch()

        // If it is invalid
        if (this.$v.formData.startDate.$anyError || this.$v.formData.endDate.$anyError) {
          // Show a snackbar
          this.$store.dispatch("toasts/add", {
            text: "Please enter a valid date range",
          })
        }
      }
    },

    /**
     * Run the validations and if correct
     * If valid, emit to the parent
     *
     * @returns {void}
     */
    async validate() {
      // Start isValidating
      this.isValidating = true

      // If the user made some form changes
      if (this.didEditData === true) {
        // Wait for 200 milliseconds
        await wait(200)

        // Run the validations
        await this.$v.formData.$touch()

        // If it is valid
        if (!this.$v.formData.$anyError) {
          // Hide the didEdit text
          this.didEditData = false
        }

        // End the validating
        this.isValidating = false

        // End further execution
        return
      }

      // Check if the inputs are valid
      await this.$v.formData.$touch()

      // If it is invalid
      if (this.$v.formData.$anyError) {
        // Hide the validating
        this.isValidating = false

        return
      }

      // Loop through each report
      for (const report of this.formData.reports) {
        // If none of the platform is selected
        if (Object.values(report.platforms).filter(v => v).length === 0) {
          // Show a toast message
          this.$store.dispatch("toasts/add", { text: "Please select a social network for brand named: " + report.name })

          // Hide the validating
          this.isValidating = false

          // Stop further execution
          return
        }

        // If the user has selected tiktok but hashtags is empty
        if (report.platforms.tiktok === true && report.hashtags.length === 0) {
          // Show a toast message
          this.$store.dispatch("toasts/add", { text: "Tiktok reports require at least one hashtag to work" })

          // Hide the validating
          this.isValidating = false

          // Stop further execution
          return
        }

        // If the user has not entered any hashtags or usernames
        if (report.hashtags.length === 0 && report.usernames.length === 0) {
          // Show a toast message
          this.$store.dispatch("toasts/add", { text: "Please enter at least one hashtag or username for brand named: " + report.name })

          // Hide the validating
          this.isValidating = false

          // Stop further execution
          return
        }
      }

      // Otherwise, emit the event
      this.$emit("submit", JSON.parse(JSON.stringify(this.formData)))

      // Hide the validating
      this.isValidating = false
    },

    /**
     * Handle the hashtag input
     *
     * @param {Object} report
     * @returns {void}
     */
    handleHashtagInput(report) {
      // Go through each hashtag, and remove the # from it and then add again at the start
      report.hashtags.$model = report.hashtags.$model
        // Remove all the hash symbols
        .map((item) => item.replaceAll("#", "").trim())
        // Add the hash symbol again
        .map((item) => `#${item}`)
        // Extract the value from input
        .map((item) => {
          // Get the value
          const value = regex.hashtag.exec(item)

          // If the value is not null
          if (value !== null) {
            // Return the value
            return value[0]
          }
          // Otherwise
          else {
            // Return null
            return null
          }
        })
        // Filter out the null values
        .filter((item) => item !== null)
        // Remove the duplicates
        .filter((item, index, self) => self.indexOf(item) === index)

      // Touch the input
      report.hashtags.$touch()
    },

    /**
     * Handle the username input
     *
     * @param {Object} report
     * @returns {void}
     */
    handleUsernameInput(report) {
      // Go through each username, and remove the @ from it and then add again at the start
      report.usernames.$model = report.usernames.$model
        // Remove all the @ symbols
        .map((item) => item.replaceAll("@", "").trim())
        // Add the @ symbol again
        .map((item) => `@${item}`)
        // Extract the value from input
        .map((item) => {
          // Get the value
          const value = regex.username.exec(item)

          // If the value is not null
          if (value !== null) {
            // Return the value
            return value[0]
          }
          // Otherwise
          else {
            // Return null
            return null
          }
        })
        // Filter out the null values
        .filter((item) => item !== null)
        // Remove the duplicates
        .filter((item, index, self) => self.indexOf(item) === index)

      // Touch the input
      report.usernames.$touch()
    },

    /**
     * Get the error message for hashtag input
     *
     * @returns {String}
     */
    getHashtagErrorMessage(report) {
      // If the user has selected tiktok but hashtags is empty
      if (report.platforms.tiktok === true && report.hashtags.$model.length === 0) {
        // Return the error message
        return "Please enter at least one hashtag"
      }

      // If the user has not entered any hashtags or usernames
      if (report.hashtags.length === 0 && report.usernames.$model.length === 0) {
        // Return the error message
        return "Please enter at least one hashtag"
      }

      // If the user has entered more than 10 hashtags
      if (report.hashtags.$model.length > 10) {
        // Return the error message
        return "Please enter at most 10 hashtags"
      }

      // If the user has entered invalid hashtags
      if (report.hashtags.$anyError) {
        // Return the error message
        return "Please enter valid hashtags"
      }

      // Otherwise, return null
      return null
    },

    /**
     * Push a new report to the form
     *
     * @returns {void}
     */
    pushReportToForm() {
      this.formData.reports.push(initialReportData())
    }
  }
}
</script>

<style lang="stylus" scoped>
.opaque
  opacity 0.2

.contain-select-width
  min-width unset !important
  width unset !important
</style>
